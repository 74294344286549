import styled from 'styled-components';

export const PageWrapper = styled.div`
position: relative;
width: 100%;
height: 100%;
text-align: center;
align-self: center;
border-radius: 20px;
padding-top: 50px;
@media (max-width: 870px) {
    width: 100%;
}
`;

export const ContentWrapper = styled.div`
position: relative;
display: flex;
width: 70%;
margin: 0 auto;
height: 100%;
background: linear-gradient(#24293C, rgba(0,0,0, .5));
border: 2px solid black;
border-radius: 20px;
height: 100%;
color: white;
flex-direction: column;
align-items: center;
@media (max-width: 870px) {
    width: 90%;
}
`;

export const ImageWrapper = styled.img`
width: 100%; 
min-width: 250px; 
height: auto; 
padding-bottom: 0px;
background-color: white;
align-items: center;
border: 3px solid #D4AC58;
border-radius: 5px;
`;

export const ImageContainer = styled.div`
width: 50%;
max-width: 50%;
padding: 0px;
@media (max-width: 1200px) {
    width: 70%;
    max-width: 70%;
}
@media (max-width: 1200px) {
${props => props.hideOnCol && ('display: none')}
}
@media (min-width: 1200px) {
    ${props => props.hideOnRow && ('display: none')}
}

`;

export const TopTextWrapper = styled.div`
width: 70%;
font-size: 1.1vw;
text-align: left;
font-family: Gibson;
color: lightgrey;
padding: 0px 50px;
line-height: auto;
@media (max-width: 1200px) {
    font-size: 14px;
    padding: 0px;
    padding-top: 20px;
    width: 70%;
}
`;

export const rowStyles = `
padding: 0% 5%;
justify-content: space-around;
height: 100%;
align-items: center;
@media (max-width: 1200px) {
    align-items: center;
    width: 100%;
    padding: 0px;
    flex-direction: column;
    margin-bottom: 15px;
}
`;

export const firstRowStyles = `
    @media (max-width: 870px) {
        padding-top: 100px;
    }
`;

export const TextParagraph = styled.div`
    padding-top: ${props => props.first ? '20px' : '0px'};
    padding-bottom: 20px;
`;

export const TextLink = styled.a`
    text-decoration: none;
    color: #D4AC58;
    &:hover {
        color: white;
    }
`;

export const SectionBannerWrapper = styled.div`
margin-top: 25px;
width: 100%;
`;

export const VideoWrapperWrapper = styled.div`
    position: relative;
    width: 70%;
    padding: 10px;
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    z-index: 150;
    ${props => props.fixed && 'position: fixed;'}

    @media (max-width: 870px) {
        padding-top: 25px
    }
`;

export const VideoWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    border: 3px solid white;
    border-radius: 4px;
    padding-bottom: ${(1 / (16/9)) * 100}%;
`;

export const VideoContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: visible;
`;

export const VideoSubText = styled.div`
    color: white;
    font-size: 12px;
    font-family: Gibson;
    padding-top: 5px;
    text-decoration: underline;
`;

