import React from 'react';
import SectionBanner from '../../../components/SectionBanner';
import Row from '../../../components/Row';
import {
    isBrowser,
} from "react-device-detect";
import BlogTextHeader from '../../../components/BlogTextHeader';
import ReactPlayer from 'react-player'
import {
    PageWrapper,
    ContentWrapper,
    SectionBannerWrapper,
    firstRowStyles,
    rowStyles,
    ImageContainer,
    ImageWrapper,
    TextParagraph,
    TopTextWrapper,
    VideoWrapper,
    VideoContainer,
    VideoWrapperWrapper,
    VideoSubText,
} from '../blog.styles';

const topText = {
    top: "Today is the first day of the new year which means new year's resolutions are in full effect. To kick things off with a bang, I want to announce 'Neon Skies': a virtual reality game currently in development.",
    mid: "Neon Skies has been a passion project since I had the idea for the game in early May 2019, and in 2020 I want to make as much progress on the game as I can. This year I want to record as much of the Neon Skies development process as I can and share the experience as a solo developer.",
    header: "Revealing Neon Skies"
};
const midText = {
    top: "Neon Skies is an original action flight-simulation game set in the virtual world of Arcadia. Players take the role of rookie pilots just beginning their career in the Neon Skies Competitive League, and must learn to outmaneuver the competition to rise in the league rankings. Leveling up in the league will unlock ships, in-game sponsors and more. Fame and glory await only the most skilled ace pilots!",
    mid: "",
    header: "What is Neon Skies?"
};
const bottomText = {
    top: "As of today, I am unsure when Neon Skies will be playable to the public. Given that this will be my first official release, I want to be sure that the game has a fine coat of polish on it before even considering something like early access. There is so much to do and learn, but I am so excited for what's to come!",
    mid: `The idea behind Neon Skies was quite simple when I had it, and it infuses a lot of my favorite things: electronic music, Tron-inspired gameplay, `,
    header: "When and Why?"
};
const vrText = {
    top: "When VR was first announced, I didn't think much of it. But after playing a few games at PAX and New York Comic Con, I started to believe in it a lot more. You can truly build worlds with this technology, and thats what I want to do!",
    mid: "At the time of writing this, I currently have an Oculus Rift S. I have sunk a good amount of time into Unity development and I'm beginning to work on 'NEON SKIES!'",
    header: "?"
};


const finalPosterSmall = require('../../../assets/blogs/nsAnnouncement/finalPosterSmall.png');
const testFlight = require('../../../assets/blogs/nsAnnouncement/testLaunch.gif');
const firstFrame = require('../../../assets/blogs/nsAnnouncement/frameAvatar.gif');
const elevator = require('../../../assets/neonskies/elevatorWIP.gif');

const NSAnnouncement = () => {
    const isWeb = isBrowser;
    return (
        <PageWrapper>
            <ContentWrapper>
                <SectionBannerWrapper>
                    <SectionBanner align="right" text={"The Reveal"} />
                </SectionBannerWrapper>
                <VideoWrapperWrapper>
                    <VideoWrapper>
                        <VideoContainer>
                            <ReactPlayer
                                width="100%"
                                height="100%"
                                url="https://youtu.be/b7EHpUyxz-o"
                            />
                        </VideoContainer>
                    </VideoWrapper>
                    <VideoSubText>
                        Watch the teaser trailer!
                    </VideoSubText>
                </VideoWrapperWrapper>
                <Row style={[rowStyles, !isWeb ? firstRowStyles : null ]}>
                    <ImageContainer>
                        <ImageWrapper src={finalPosterSmall} alt="Neon Skies Poster" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={topText.header} />
                        <TextParagraph first>
                        {topText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {topText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer hideOnRow>
                        <ImageWrapper src={firstFrame} alt="test Flight gif" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={midText.header} />
                        <TextParagraph first>
                        {midText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {midText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                    <ImageContainer hideOnCol>
                        <ImageWrapper src={firstFrame} alt="test Flight gif" />
                    </ImageContainer>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer>
                        <ImageWrapper src={elevator} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={vrText.header} />
                        <TextParagraph first>
                        {vrText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {vrText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer hideOnRow>
                        <ImageWrapper src={testFlight} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={bottomText.header} />
                        <TextParagraph first>
                        {bottomText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {bottomText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                    <ImageContainer hideOnCol>
                        <ImageWrapper src={testFlight} alt="Nicky B" />
                    </ImageContainer>
                </Row>
            </ContentWrapper>
        </PageWrapper>
    )
}

export default NSAnnouncement;