import React from 'react';
import SectionBanner from '../../components/SectionBanner';
import Row from '../../components/Row';
import ImageButton from '../../components/ImageButton';


const goldHeartImage = require('../../assets/GoldHeart.png')
const goldHeartText = "Hello, World!"
const goldHeartDate = '1/1/2020';
const goldHeartSummaryText = "Introducing Gold Heart Games: an independent development studio focused on VR game development! Click here to learn more about this studio and what the plans are for the future!"

const NeonSkiesImage = require('../../assets/blogs/nsAnnouncement/arenaSquare.png');
const NeonSkiesText = "Neon Skies Revealed!"
const NeonSkiesDate = '1/1/2020';
const NeonSkiesSummaryText = "Neon Skies has officially been revealed! Click here to learn more about the original flight-action VR game and watch the teaser!"

const AnnouncementsRowStyles = `
    justify-content: space-around;
    background: linear-gradient(rgba(28, 48, 122, .2), rgba(0,0,0, .5));
    border: 2px solid black;
    border-radius: 20px;
    width: 100%;
    padding: 40px 0px 20px 0px;
    align-items: center;
    @media (max-width: 1220px) {
        flex-direction: column;
        width: fit-content;
    }
`;

const Announcements = () => {

return (
    <>
        <SectionBanner text={'Dev Logs'} />
        <Row style={AnnouncementsRowStyles}>
                <ImageButton
                    image={NeonSkiesImage}
                    text={NeonSkiesText}
                    link={'/blog/2'}
                    summaryText={NeonSkiesSummaryText} 
                    date={NeonSkiesDate}
                />
                <ImageButton
                    image={goldHeartImage}
                    text={goldHeartText}
                    link={'/blog/1'}
                    summaryText={goldHeartSummaryText} 
                    date={goldHeartDate}
                />
        </Row>
    </>
    )
}

export default Announcements;