import React from 'react';
import styled from 'styled-components';
import NeonSkiesText from '../../components/NeonSkiesText';
import SectionBanner from '../../components/SectionBanner';
import TextBubble from '../../components/TextBubble';
import ImageModal from '../../components/ImageModal';
import Screenshots from './ScreenShots';
import Videos from './Videos';

const PageWrapper = styled.div`
    width: 100%;
    color: white;
    min-height: 70vw;
`;
const ContentWrapper = styled.div`
    display: flex;
    width: 90%;
    height: 100%;
    color: white;
    max-width: 1500px;
    position: relative;
    align-content: center;
    flex-direction: column;
    margin: 0 auto;
    background: linear-gradient(#24293C, rgba(0,0,0, .5));
    padding-top: 5%;
    border: 2px solid black;
    border-radius: 20px;
    @media (max-width: 870px) {
        width: 100%;
        border-radius: 0px;
        border-right: none;
        border-left: none;
    }
    @media (min-width: 1500px) {
        padding-top: 50px;
    }
`;
const Header = styled.div`
    overflow: hidden;
    font-family: JusticeSolid;
    font-size: 10vw;
    width: 100%;
    height: fit-content;
    position: relative;
    top: 50px;
    left: 0px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    max-height: 20vh;
    min-height: 10vw;
    border: 2px solid white;
    border-left: none;
    border-right: none;
    margin-bottom: 100px;
    @media (max-width: 870px) {
        min-height: 12vh;
    }
`;

const BannerWrapper = styled.div`
    padding-top: 10%;
    @media (min-width: 1500px) {
        padding-top: 100px;
    }
`;

const ImageWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    &:hover {
        cursor: pointer;
    }
`;

const ImageContainer= styled.img`
    border: 2px solid white;
    width: fit-content;
    max-width: 100%;
`;

const currentGameTextStyles = `
    font-size: 24px;
    width: 90%;
    text-align: ${props => props.align === 'right' ? 'left' : 'right'};
    text-overflow: elipsis;
    padding-bottom: 20px;
    padding-top: 20px;
    align-self: center;
    line-height: 40px;
    color: white;
    @media (max-width: 1225px) {
        position: relative;
        width: 90%;
        line-height: 30px;
        text-align: left;
        font-size: 18px;
        padding-top: 10px;

    }
    @media (max-width: 870px) {
        padding: 00px 0px 20px 0px;
        text-align: left;
        font-size: 16px;
    }
`;

const NeonSkiesImage = require('../../assets/neonskies/finalposterwithlogo.png');

const NeonSkiesDescription = `Neon Skies is the first Virtual Reality title developed by Gold Heart Games. It's an original action flight-simulation game set in the virtual world of Arcadia. Players embody rookie pilots just beginning their career in the Neon Skies Competitive League, and must learn to outmaneuver the competition to rise in the league rankings. Level up to earn unlockable ships, in-game sponsors and more. Fame and glory await only the most skilled ace pilots!`

const NeonSkiesBackstory = `In the distant future, eons past current day, Earth is in ruin. Extreme weather conditions have rendered the planet's exterior inhospitable to all acts of life. Yet in the wake of the Earth's destruction, a new frontier has emerged as the next step for humanity: the digital universe, safe haven and future for all mankind, Arcadia.`

const NeonSkiesBackstoryContinued = `Toward the end of the second millennia scientists invented a process known as "Neural-Caching"; cerebral preservation through the transference of a human conscious to machine. It was this practice that saved the remaining humans from Earth's cataclysm and allowed them to enter the virtual world of Arcadia; a digital universe and safe haven for mankind.`


const ArcadiaBackstory = `All life that enters Arcadia requires equivalent bits of memory from the world servers. Bits are an invaluable limited resource within Arcadia, and are what established the virtual world's economy. Citizens are able to  There were failsafes put in place, such as never being able lose more than the minimum value for a citizen's life`;

const PlotBackstory = `To the virtual world of Arcadia, Neon Skies has become a cultural phenomenon. The high-stakes aerial battles that define the sport are the most viewed competitions throughout the world. With only the most-skilled Frame pilots permitted to join the Neon Skies Competitive League (NSCL), each match is an action-packed adrenaline rush.`;

const PlotBackstoryEnd = `
Every match of Neon Skies pits two to twelve pilots against one another inside of Arcadia's Ico-Arenas. These monumental structures hover miles above the ground and serve as holographic boundaries for every match. At the start of the match all pilot frame's automatically initiate overdrive: increasing maximum velocity and engaging frame trails. Pilots must avoid all trails in the sky while simultaneously hunting the remaining competition. Last pilot to survive elimination claims the title of champion.
`;

const PlotBackstoryCont = `Top pilots are celebrated, presented with sponsorships and, most importantly, rewarded with Arcadia's most valuable resource: "Bits". Bits are the free space within Arcadia's servers that pilots are able to exchange for suit modifications, frame upgrades, and anything else that may provide an advantage in a match.`;

class NeonSkiesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: null,
            currentVideo: null,
        };
    }

    componentDidMount() {
        this.clearModal();
    }

    componentWillUnmount() {
        this.clearModal();
    }

    clearModal = () => {
        this.setState({
            currentImage: null,
            currentVideo: null,
        });
    }

    setModal = (val, bool) => {
        if (!bool) {
            this.setState({
                currentImage: val,
            });
        } else {
            debugger
            this.setState({
                currentVideo: val,
            });
        }
    }

    render() {
        const { currentImage, currentVideo } = this.state;
    return (
        <PageWrapper>
            <ImageModal currentImage={currentImage} currentVideo={currentVideo} clearModal={this.clearModal} />
            <Header className={"neon-skies-wipe"}>
                <NeonSkiesText isLogo/>
            </Header>
            <ContentWrapper>
                <ImageWrapper>
                    <ImageContainer
                    src={NeonSkiesImage}
                    onClick={() => {
                        this.setModal(NeonSkiesImage)
                    }} />
                </ImageWrapper>
                <BannerWrapper>
                    <SectionBanner color="#2BFDFE" text={"Description"} />
                </BannerWrapper>
                <TextBubble 
                    align={'left'}
                    isHideable
                    isVisible={true}
                    styles={currentGameTextStyles} 
                    text={NeonSkiesDescription}
                />
                <BannerWrapper>
                    <SectionBanner align="left" color="#FF8EFF" text={"Setting"} />
                </BannerWrapper>
                <TextBubble 
                    align={'right'}
                    isHideable
                    isVisible={true}
                    styles={currentGameTextStyles} 
                    text={NeonSkiesBackstory}
                />
                <BannerWrapper>
                    <SectionBanner align="right" color="#FEFF1B" text={"Plot"} />
                </BannerWrapper>
                <TextBubble 
                    align={'left'}
                    isHideable
                    isVisible={true}
                    styles={currentGameTextStyles} 
                    text={PlotBackstory}
                />
                <TextBubble 
                    align={'left'}
                    isHideable
                    isVisible={true}
                    styles={currentGameTextStyles} 
                    text={PlotBackstoryCont}
                />
                <TextBubble 
                    align={'left'}
                    isHideable
                    isVisible={true}
                    styles={currentGameTextStyles} 
                    text={PlotBackstoryEnd}
                />
                <BannerWrapper>
                    <SectionBanner align="left" color="rgb(37%, 100%, 48%)" text={"Videos"} />
                </BannerWrapper>
                <Videos setModal={this.setModal} />
                <BannerWrapper>
                    <SectionBanner text={"Screenshots"} />
                </BannerWrapper>
                <Screenshots setModal={this.setModal} />
            </ContentWrapper>
        </PageWrapper>
    )
    }
}

export default NeonSkiesPage;