import React, { useState } from 'react';
import styled from 'styled-components';
import * as firebase from 'firebase';
import SectionBanner from '../../components/SectionBanner';
import Row from '../../components/Row';

const rowStyles = `
    border: 2px solid black;
    border-radius: 10px;
    width: 600px;
    height: 100px;
    background: linear-gradient(rgba(28, 48, 122, .2), rgba(0,0,0, .5));;
    align-items: center;
    display: flex;
    justify-content: center
    margin: 0 auto;
    margin-top: 50px;
    flex-direction: column;
    @media (max-width: 1225px) {
        width: 600px;
    }
    @media (max-width: 870px) {
        width: 440px;
        padding-top: 5px;
        justify-content: center;
        height: 110px;
    }
`;

const NewsletterContainer = styled.div`
    width: 100%;
    height: 100px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 75px;
    display: flex;
    flex-direction: row;
    @media (max-width: 870px) {
        align-items: center;
        justify-content: center;
    }
`;

const TextContainer = styled.div`
    width: 90%;
    font-family: Gibson;
    text-align: center;
    color: lightgrey;
    font-size: 12px;
    color: white;
    @media (max-width: 870px) {
        font-size: 10px;
    }
`;

const TextInput = styled.input`
    width: 300px;
    justify-self: center;
    background: #2E303B;
    color: black;
    font-family: Gibson;
    color: #fdfd96;
    text-align: center;
    font-size: 12px;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-color: ${props => props.emailError ? 'red' : 'black'};
    &:focus {
        outline: none;
    }
    @media (max-width: 870px) {
        width: 200px;
        font-size: 10px;
    }
`;

const SubmitButton = styled.div`
    width: 100px;
    text-align: center;
    font-size: 12px;
    font-family: Gibson;
    border: 2px solid black;
    padding-bottom: 5px;
    padding-top: 5px;
    font: JusticeSolid;
    margin-left: 20px;
    color: white;
    background-color: ${props => props.isValidEmail ? '#28A75D' : '#B4050E'}
    ${props => props.isSubmitted && ('background-color: #D4AC58;')}
    cursor: ${props => props.isValidEmail ? 'pointer' : 'not-allowed'};
    ${props => props.isSubmitted && (`
        cursor: not-allowed;
        opacity: .7;
    `)}
    &:hover {
        opacity: ${props => props.isValidEmail ? '.7' : '1'};
        ${props => props.isSubmitted && ('opacity: .7;')}
    }
    &:active {
        opacity: ${props => props.isSubmitted ? '.7' : '.4'};
    }
`;

const textRowStyles = `
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
    @media (max-width: 870px) {
        flex-direction: row;
        padding-bottom: 20px;
        align-items: center;
    }
`;

const Newsletter = () => {
    const [state, setState] = useState({
        isSubmitted: false,
        currentEmail: '',
        emailError: false,
        isValidEmail: false,
    });
    
    const checkEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const updateEmail = (e) => {
        setState({
            ...state,
            currentEmail: e.currentTarget.value,
            isValidEmail: checkEmail(e.currentTarget.value),
            emailError: false,
        })
    }

    const onSubmitPressed = () => {
        if (checkEmail(state.currentEmail) && !state.isSubmitted) {
            // send request
            debugger
            setState({
                ...state,
                isSubmitted: true,
            })
            firebase.database().ref('emails').push({
                email: state.currentEmail
            });
        } else if (!checkEmail(state.currentEmail) && !state.isSubmitted) {
            setState({
                ...state,
                emailError: true,
            })
        }
    }

    const signUpText = "Sign up and be notified when new tutorials and game updates are posted!";
    const submitText = state.isSubmitted ? 'Submitted!' : 'Submit'

    return (
        <>
            <SectionBanner align="left" text={'Newsletter'} color="black" />
            <NewsletterContainer>
                <Row style={rowStyles}>
                    <TextContainer>
                        {signUpText}
                    </TextContainer>
                    <Row style={textRowStyles}>
                        <TextInput placeholder="Enter Email..." type="text" onChange={updateEmail} emailError={state.emailError} />
                        <SubmitButton isValidEmail={state.isValidEmail} onClick={onSubmitPressed} isSubmitted={state.isSubmitted}>
                            {submitText}
                        </SubmitButton>
                    </Row>
                </Row>
            </NewsletterContainer>
        </>
    )
}

export default Newsletter;