import React, { useState } from 'react';
import Slider from "react-slick";
import styled, { keyframes } from 'styled-components';
import TextBubble from '../../components/TextBubble';
import SectionBanner from '../../components/SectionBanner';
import { currentGameData } from './CurrentGamesData';
import Row from '../../components/Row';

const currentGameTextStyles = `
    font-size: 16px;
    width: 300px;
    overflow: hidden;
    text-overflow: elipsis;
    padding-bottom: 20px;
    padding-top: 10px;
    line-height: 30px;
    @media (max-width: 1225px) {
        position: relative;
        width: 100%;
        text-align: center;
    }
    @media (max-width: 870px) {
        padding: 00px 0px 20px 0px;
    }
`;

const textHeaderStyles = `
    font-size: 20px;
    width: 300px;
    overflow: hidden;
    text-overflow: elipsis;
    font-family: JusticeSolid;
    color: ${props => props.color};
    @media (max-width: 1225px) {
        position: relative;
        width: 100%;
        text-align: center;
        max-width: 500px;
    }
`;

const StyledImg = styled.img`
    width: 400px;
    transition: .5s ease-in-out;
    opacity: 1;
    border: 5px solid white;
    margin: 0 auto;
    &:hover {
        width: 400px;
        cursor: pointer;
        opacity: .7;
    }
    @media (max-width: 870px) {
        width: 250px;
        transition: none;
        opacity: 1;
        margin-bottom: 10px;
        &:hover {
            animation: none;
            cursor: default;
            width: 250px;
        }
    }
    @media (min-width: 1920px) {
        width: 500px;
    }
`;

const TextWrapper = styled.div`
    width: 25%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    text-align: ${props => props.align ? props.align : 'center'}
    position: relative;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 1225px) {
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 870px) {
        align-self: center;
    }
    @media (min-width: 1920px) {
        width: 10%;
    }
`;

const TextPackager = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${props => props.margin ? `margin: ${props.margin};` : 'margin: 0 auto;'}
    @media (max-width: 870px) {
        margin-top: 10px;
        align-items: center;
    }
`;

const StyledSlider = styled(Slider)`
    display: flex;
    justify-self: center;
    max-width: 400px;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 870px) {
        width: 250px;
    }
    @media (min-width: 1920px) {
        max-width: 500px;
        width: 500px;
    }
`;
const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const OnPressContainer = styled.div`
    font-size: 12px;
    font-family: Gibson;
    color: lightgrey;
    width: 100%;
    padding-top: 5px;
    text-align: center;
    @media (max-width: 870px) {
        padding-top: 0px;
        padding-bottom: 20px;
    }
`;


const DEVELOPMENT_STATUS = `Development Status`;
const PLATFORMS = `Platforms`;
const DESCRIPTION = 'Description';
const TITLE = 'Title';

const NeonSkiesPoster = require('../../assets/nsSmallPoster.png')
const SurvivalOfTheTypistPoster = require('../../assets/sottSmallPoster.png')


const CurrentGames = () => {
    const [state, setState] = useState({
        isVisible: true,
        currentGameIndex: 0,
    });
    const { isVisible, currentGameIndex} = state;
    const afterChange = (index) => {
        setState({
            isVisible: true,
            currentGameIndex: index,
        })
    } 
    const beforeChange = (oldIndex, newIndex) => {
        setTimeout(()=> setState({
            currentGameIndex: state.currentGameIndex,
            isVisible: false,
        }), 10)

    } 
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: afterChange,
        beforeChange: beforeChange,
    };

    const currentGame = currentGameData[currentGameIndex];

        return (
            <>
                <SectionBanner text={'Current Games'} />
                <Row>
                    <StyledSlider {...settings}>
                            <Column>
                                <StyledImg
                                    alt={"Neon Skies Poster"}
                                    src={NeonSkiesPoster}
                                />
                                <OnPressContainer>
                                    {currentGame.press}
                                </OnPressContainer>
                            </Column>
                            <Column>
                                <StyledImg
                                    alt={"Survival of the Typist Poster"}
                                    src={SurvivalOfTheTypistPoster}
                                    onClick={() => { return currentGame.link ? window.open(currentGame.link, "_self") : null }}
                                />
                                <OnPressContainer>
                                    {currentGame.press}
                                </OnPressContainer>
                            </Column>
                    </StyledSlider>
                    <TextWrapper align={'left'}>
                        <TextPackager>
                            <TextBubble 
                                align={'left'} 
                                color={"white"}
                                styles={textHeaderStyles} 
                                text={TITLE}
                                translate={'translateX(15px)'} 
                                />
                            <TextBubble 
                                align={'left'} 
                                isHideable 
                                isVisible={isVisible} 
                                styles={currentGameTextStyles} 
                                text={currentGame.title} 
                                translate={'translateX(15px)'}
                                />
                        </TextPackager>
                        <TextPackager>
                            <TextBubble 
                                align={'left'} 
                                color={"#85FFFF"}
                                styles={textHeaderStyles} 
                                text={DEVELOPMENT_STATUS}
                                translate={'translateX(15px)'} 
                                />
                            <TextBubble 
                                align={'left'} 
                                isHideable 
                                isVisible={isVisible} 
                                styles={currentGameTextStyles} 
                                text={currentGame.status} 
                                translate={'translateX(15px)'}
                                />
                        </TextPackager>
                        <TextPackager>
                            <TextBubble 
                                align={'left'} 
                                color={"#FFB0FF"}
                                styles={textHeaderStyles} 
                                text={PLATFORMS}
                                translate={'translateX(15px)'} 
                                />
                            <TextBubble 
                                align={'left'} 
                                isHideable 
                                isVisible={isVisible} 
                                styles={currentGameTextStyles} 
                                text={currentGame.platforms} 
                                translate={'translateX(15px)'}
                                />
                        </TextPackager>
                    </TextWrapper>
                    <TextWrapper>
                        <TextPackager>
                            <TextBubble
                                align={'left'}
                                color={'#fdfd96'}
                                styles={textHeaderStyles} 
                                text={DESCRIPTION} 
                            />
                            <TextBubble 
                                align={'left'}
                                isHideable
                                isVisible={isVisible}
                                styles={currentGameTextStyles} 
                                text={currentGame.description}
                            />
                        </TextPackager>
                    </TextWrapper>
                </Row>                
            </>
        )
}

export default CurrentGames;