import React from 'react';
import styled from 'styled-components';
import SectionBanner from '../../components/SectionBanner';
import Row from '../../components/Row';
import { TwitterTimelineEmbed } from 'react-twitter-embed';


const TwitterRowStyles = `
    justify-content: space-around;
    background: linear-gradient(rgba(28, 48, 122, .2), rgba(0,0,0, .5));
    border: 2px solid black;
    border-radius: 20px;
    width: 100%;
    padding: 40px 0px 20px 0px;
    align-items: center;
    @media (max-width: 1220px) {
        flex-direction: column;
        width: fit-content;
        max-width:
    }
`;

const TwitterWrapper = styled.div`
    background: rgb(11%, 11%, 14%);
    padding: 10px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    width: 380px;
    justify-content: center;
    min-width: fit-content;
    margin: 10px 25px;
    @media (max-width: 1220px) {
        width: auto;
    }
`;

const Twitter = () => {

return (
    <>
        <SectionBanner align={'left'} text={'Twitter'} color="#12DAFD" />
        <Row style={TwitterRowStyles}>
                <TwitterWrapper>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="nickyboccuzzi"
                        theme="dark"
                        noFooter
                        borderColor="#D4AC58"
                        options={{height: '430px', width: '300px'}}
                        />
                </TwitterWrapper>
                <TwitterWrapper>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="goldheartgames"
                        theme="dark"
                        noFooter
                        borderColor="#D4AC58"
                        options={{height: '430px', width: '300px'}}
                        />
                </TwitterWrapper>
                <TwitterWrapper>
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="neonskiesvr"
                        theme="dark"
                        noFooter
                        borderColor="#D4AC58"
                        options={{height: '430px', width: '300px'}}
                        />
                </TwitterWrapper>
        </Row>
    </>
    )
}

export default Twitter;