import React from 'react';
import styled from 'styled-components';
import { render } from 'react-dom';
import * as firebase from 'firebase';
import { HashRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Footer from './components/Footer';

const StyledHashRouter = styled(HashRouter)`
    display: flex;
    position: relative;
    flex-direction: column;
`;

var firebaseConfig = {
    apiKey: "AIzaSyCvfWVbrLvwxbt3ibcIkuvcgEbxzx-SeOo",
    authDomain: "goldheartgames-436c0.firebaseapp.com",
    databaseURL: "https://goldheartgames-436c0.firebaseio.com",
    projectId: "goldheartgames-436c0",
    storageBucket: "goldheartgames-436c0.appspot.com",
    messagingSenderId: "633532447393",
    appId: "1:633532447393:web:2129540d9e0d9b6adbcee0",
    measurementId: "G-F2VYMKQVQN"
  };
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

render(
<StyledHashRouter>
    <App />
    <Footer />
</StyledHashRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
