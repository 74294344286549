import React from 'react';
import styled from 'styled-components';
import Row from '../../components/Row';
import ImageButton from '../../components/ImageButton';
import SectionBanner from '../../components/SectionBanner';

const GAME_DEV_LOGS = 'Dev Logs';
const AnnouncementsRowStyles = `
    justify-content: space-between;
    border-radius: 20px;
    width: 100%;
    background: linear-gradient(rgba(28, 48, 122, .2), rgba(0,0,0, .5));
    border: 2px solid black;
    @media (max-width: 870px) {
        width: 100%;
    }
    overflow: wrap;
    padding: 30px;
    @media (max-width: 1220px) {
        padding: 0px;
        align-items: center;

    }
`;
const Column = styled.div`
    padding: 20px 20px 0px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
`;

const ContentWrapper = styled.div`
    width: 100%;
    height: auto;
    padding-top: 50px;
`;



const goldHeartImage = require('../../assets/GoldHeart.png')
const goldHeartText = "Hello, World!"
const goldHeartDate = '1/1/2020';
const goldHeartSummaryText = "Introducing Gold Heart Games: an independent development studio focused on VR game development! Click here to learn more about this studio and what the plans are for the future!"

const NeonSkiesImage = require('../../assets/blogs/nsAnnouncement/arenaSquare.png');
const NeonSkiesText = "Neon Skies Revealed!"
const NeonSkiesDate = '1/1/2020';
const NeonSkiesSummaryText = "Announcing Neon Skies: an original action flight-simulation VR game! Click here to learn more about the title and watch the teaser!"

const List = () => {
    return (
    <ContentWrapper>
        <SectionBanner text={GAME_DEV_LOGS} />
        <Column>      
            <Row style={AnnouncementsRowStyles}>
                <ImageButton
                        image={NeonSkiesImage}
                        text={NeonSkiesText}
                        link={'/blog/2'}
                        summaryText={NeonSkiesSummaryText} 
                        date={NeonSkiesDate}
                />
                <ImageButton image={goldHeartImage} text={goldHeartText} link={'/blog/1'} summaryText={goldHeartSummaryText} date={goldHeartDate} />
                
            </Row>
        </Column>
    </ContentWrapper>
    )
};

export default List;