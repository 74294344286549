import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaHeart } from 'react-icons/fa';

const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 15%;
    min-height: 1000px;
    color: white;
`;
const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    color: white;
    flex-direction: column;
    align-items: center;
`;
const Header = styled.div`
    display: flex;
    flex-direction: row;
    font-family: JusticeSolid;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '20px'};
    @media (max-width: 870px) {
        font-size: 20px;
    }
`;

const AboutText = styled.div`
    padding: 20px;
    font-size: 14px;
    font-family: Canossa;
    color: lightgrey;
    line-height: 30px;
    padding-bottom: 50px;
`;
const AboutWrapper = styled.div`
    width: 70%;
    text-align: center;
    align-self: center;
    border-radius: 20px;
    border: 2px solid black;
    background: linear-gradient(rgba(28, 48, 122, .2), rgba(0,0,0, .5));
    margin-top: 20px;
    height: 100%;
    @media (max-width: 870px) {
        width: 100%;
    }
`;
const GoldHeart = styled.div`
    padding-left: 8px;
    color: #D5AC57;
`;

const StyledFaHeart = styled(FaHeart)`
    color: #D5AC57;
    font-size: 40px;
`;

const AboutPage = () => {
    return (
        <PageWrapper>
            <ContentWrapper>
                <Header fontSize={24}>
                    About
                    <GoldHeart>
                        Gold Heart
                    </GoldHeart>
                </Header>
                <AboutWrapper>
                    <AboutText>
                        Gold Heart Games is an independent video game studio founded in late 2018 by me, Nicky Boccuzzi. This studio's primary ambition is to take ideas and dreams from my imagination and translate them to what I consider the most immersive entertainment medium: games. Throughout this creative process, I will be exploring the intricacies of game development. From computer programming to 3D modeling, sound design, and narrative creation: I want to share what this experience is like for an independent developer.
                    </AboutText>
                    <StyledFaHeart />
                </AboutWrapper>
            </ContentWrapper>
        </PageWrapper>
    )
}

export default AboutPage;