import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const NeonLetter = styled.div`
    color: ${props => props.primary || 'white'}
    ${props => `font-family: ${props.font ? props.font : 'Lunasol'};`}
    letter-spacing: 1px;
    align-items: center;
    z-index: 10;
    display: flex;
    flex-direction: row;
    height: 100%;
    @media (max-width: 870px) {
        ${props => props.isLogo && (`font-size: 10vw;`)}
    }
`
const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    ${props => !props.isLogo && (
        `font-size: ${props.fontSize ? props.fontSize : '20'}px;`
    )}
    @media (min-width: 2000px) {
        ${props => !props.isLogo && ('font-size: 24px;')}
    }
    z-index: 10;
    text-decoration: none;
    margin: .5vmax;
    @media (max-width: 870px) {
            cursor: default;
            font-size: 16px;
            ${props => !props.isLogo && (`margin-right: 10px;`)}
    }
    &:hover ${NeonLetter} {
        ${props => props.isBrowser ? 'opacity: .6;' : null}
        cursor: pointer;
    }
    &:active ${NeonLetter} {
        ${props => !props.isBrowser ? 'opacity: .6;' : null}
        @media (max-width: 870px) {
            cursor: default;
        }
    }


    ${props => props.isLogo && (
        `&:hover ${NeonLetter} {
        opacity: 1;
        cursor: default;
    }
    &: hover {
        text-decoration: none;
    }`)}
`

const NeonSkiesText = (props) => {
    const { isLogo, fontSize, isBrowser } = props;

    const className = isLogo ? "lunasol" : "hvr-sink link-color lunasol neon-white";

    return(
        <StyledLink isBrowser={isBrowser} style={{textDecoration: 'none'}} isLogo={isLogo} className={className} fontSize={fontSize} to="/neon-skies">
            <NeonLetter isLogo={isLogo} primary="#2BFDFE">
                N
            </NeonLetter>
            <NeonLetter isLogo={isLogo} primary="#FFFF1C">
                E
            </NeonLetter>
            <NeonLetter isLogo={isLogo} primary="#FF8EFF">
                O
            </NeonLetter>
            <NeonLetter isLogo={isLogo} primary="#5eff7b">
                N
            </NeonLetter>
            <NeonLetter isLogo={isLogo}>
                &nbsp;SKIES
            </NeonLetter>
        </StyledLink>
    )
}

export default NeonSkiesText;