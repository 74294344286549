import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const fade = () => keyframes`
    in {
        from: {
            opacity: 1;
        } 
        to: {
            opacity: 0;
        }
    }
    out {
        from: {
            opacity: 0;
        }
        to: {
            opacity: 1;
        } 
    }
`;

const fadeAnimation = props =>
    css`
    ${fade} .5s linear;
`

const Wrapper = styled.div`
    width: 100%;
    border: 2px solid black;
    background: rgb(11%, 11%, 14%);
    border-radius: 10px;
    transition: .5s;
    height: auto;
    max-height: fit-content;
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    &:hover {
        animation: ${fadeAnimation} 1s linear;
        opacity: .3;
        text-decoration: none;
    }

    @media (max-width: 1220px) {
        width: 85%;
        animation: none;
        margin-bottom: 15px;
        margin-top: 15px;
    }

`;

const ImageButtonWrapper = styled(Link)`
    height: fit-content;
    width: 100%;
    opacity: 1;
    @media (max-width: 870px) {
        padding-top: 10px;
    }

    &:hover {
        text-decoration: none;
    }
    ${props => props.placeholder && (`
        transition: none;
        animation: none;
        cursor: default;
        &:hover {
            animation: none;
            opacity: 1;
            text-decoration: none;
        }
    `)}
`;

const ImageButtonImage = styled.img`
    max-width:100%;
    max-height:100%;
    border: 2px solid black;
`;

const ImageWrapper = styled.div`
    height: 250px;
    max-height: 250px;
    min-height: 250px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 10px;
    width: 100%;
    background: rgb(11%, 11%, 14%);
`;

const ImageButtonText = styled.div`
    padding: 10px;
    color: white;
    text-align: left;
    font-size: 24px;
    font-family: Canossa;
    width: 100%;
    height: 65px;
    background: #0F1014;
    display: flex;
    flex-direction: row;
    @media (max-width: 870px) {
        font-size: 17px;
    }
    &:hover {
        text-decoration: none;
    }
`;

const ImageButtonDate = styled.div`
    position: absolute;
    right: 0;
    color: white;
    text-align: left;
    font-size: 16px;
    font-family: Canossa;
    background: #0F1014;
    padding: 5px;

    @media (max-width: 870px) {
        font-size: 13px;
    }
    &:hover {
        text-decoration: none;
    }
`;

const ImageButtonSummary = styled.div`
    color: lightgrey;
    padding: 0px 10px 10px 10px;
    text-align: left;
    font-size: 16px;
    font-family: Gibson;
    width: 100%;
    height: 135px;
    background: #0F1014;
    @media (max-width: 870px) {
        font-size: 16px;
    }
`;

const ImageButtonTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-apart;
    position: relative;
    padding-top: 10px;
    height: fit-content;
`;

const ImageButton = ({
    date,
    link,
    image,
    placeholder,
    summaryText,
    text,
}) => {
    const [state, setState] = useState({ isHovered: false })
    const { isHovered } = state;

    const setHovered = (bool) => {
        setState({ isHovered: bool })
    }

    return (
    <Wrapper>
        <ImageButtonWrapper
            isHovered={isHovered}
            onmouseover={() => setHovered(true)}
            onmouseout={() => setHovered(false)}
            to={link}
            fadeAnimation={fadeAnimation}
            placeholder={placeholder}
        >
            <ImageWrapper>
                <ImageButtonImage src={image} />
            </ImageWrapper>
            <ImageButtonTextWrapper>
                <ImageButtonText>
                    {text}
                </ImageButtonText>
                {date && (<ImageButtonDate>
                    {date}
                </ImageButtonDate>)}
            </ImageButtonTextWrapper>
            <ImageButtonSummary>
                {summaryText}
            </ImageButtonSummary>
        </ImageButtonWrapper>
    </Wrapper>
    )
};

export default ImageButton;