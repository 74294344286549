import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const StyledBar = styled.div`
    position: absolute;
    background-color: rgba(0, 0, 0, .2);
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    z-index: 1;
    @media (max-width: 1200px) {
        display: none;
    }
    border-bottom: 1px solid black;

`;
const StyledLogoWrapper = styled.div`
    padding: 0px 30px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    &:hover {
        text-decoration: none;    
        opacity: .8;    
    }
`;
const StyledLogoText = styled.div`
    width: fit-content;
    height: fit-conent;
    display: flex;
    flex-direction: row;
    font-family: Justice;
    color: #D4AC58;
    text-decoration: none;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '1.25vmax'};
    &:hover {
        text-decoration: none;        
    }
    text-align: center;
    align-items: flex-start;
    margin-right: 10px;
`;
const StyledLogoWrapperAbsolute = styled(Link)`
    padding: 0px 30px;
    position: absolute;
    left: 0;
    right: 0;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover ${StyledLogoText} {
        ${props => props.shiny && (`font-family: JusticeSolid;`)}
    }
`;
const LogoWrapper = styled.div`
    height: 100%;
    width: fit-content;
    align-items: center;
    display: flex;
`;

const GoldHeartBar = () => {
    return (
        <StyledBar>
            <LogoWrapper>
                <StyledLogoWrapper>
                    <StyledLogoText>
                        GOLD HEART GAMES
                    </StyledLogoText>
                </StyledLogoWrapper>
                <StyledLogoWrapperAbsolute shiny className="gold-heart-wipe" to="/">
                    <StyledLogoText>
                        GOLD HEART GAMES
                    </StyledLogoText>
                </StyledLogoWrapperAbsolute>
            </LogoWrapper>
        </StyledBar>
    );
}

export default GoldHeartBar;