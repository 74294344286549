import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Route } from 'react-router-dom'
import Switch from 'react-router-transition-switch'
import Fader from 'react-fader'
import List from './list';
import Hello from './BlogPages/hello';
import NSAnnouncement from './BlogPages/nsAnnouncement';



const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 0%;
    min-height: 600px;
    color: white;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: 870px) {
        flex-direction: row;
    }
    width: 100%;
    height: 100%;
    color: white;
`;

const AboutText = styled.div`
    padding: 20px;
    font-family: Cossas;
`;

const Header = styled.div`
    font-family: JusticeSolid;
    text-align: center;
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '20px'};
`;

const Blogs = () => ([
    <Route exact path="/blog" component={List} />,
    <Route exact path="/blog/1" component={Hello} />,
    <Route exact path="/blog/2" component={NSAnnouncement} />
])

export default Blogs;