import React from 'react';
import styled from 'styled-components';

const BannerWrapper = styled.div`
    width: 100%;
    margin-vertical: 50px;
    ${props => props.align && (`
        text-align: ${props.align === 'left' ? 'right' : 'left'};
        padding-left: 0px;
        padding-right: 1.5vw;
    `)}
    background: linear-gradient(to ${props => props.align ? props.align : 'right'}, ${props => props.color}, transparent 35%)
`;

const TextWrapper = styled.div`
    font-size: 2.75vw;
    font-family: JusticeSolid;
    color: white;
    padding-left: 1.5vw;
    text-shadow: -3px 3px black;
    @media (min-width: 1400px) {
        font-size: 40px;
    }
    @media (max-width: 870px) {
        margin-top: 20px;
        font-size: 20px;
    }
`;

const SectionBanner = ({
    align,
    color,
    text,
}) => {
    return (
        <BannerWrapper align={align} color={color}>
            <TextWrapper align={align}>
                {text}
            </TextWrapper>
        </BannerWrapper>
    )
}

SectionBanner.defaultProps = {
    color: '#D4AC58',
}

export default SectionBanner;