import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const fade = () => keyframes`
    in {
        from: {
            opacity: 1;
        } 
        to: {
            opacity: 0;
        }
    }
    out {
        from: {
            opacity: 0;
        }
        to: {
            opacity: 1;
        } 
    }
`;

const fadeAnimation = css`
    ${fade} linear;
  `


const StyledTextBubble = styled.div`
    text-align: ${props => props.align ? props.align : 'center'}
    color: ${props => props.color ? props.color : '#d1d1d1'};
    font-family: ${props => props.fontFamily ? props.fontFamily : 'Gibson'};
    font-size: ${props => props.fontSize ? `${props.fontSize}px` : '12px'};
    margin: ${props => props.margin};
    transition: .5s ease-in-out;
    ${props => props.styles}
    ${props => props.isHideable && (`
        ${props.isVisible ? `animation: ${fadeAnimation}; transform: ${props.translate ? props.translate : 'translateX(15px)'};` : `opacity: 0;`}
        @media (max-width: 1225px) {
            ${props.isVisible ? `animation: ${fadeAnimation}; transform: translateY(15px);` : `opacity: 0;`}
        }
        @media (max-width: 870px) {
            ${props.isVisible ? `animation: ${fadeAnimation};` : `opacity: 0;`}
        }
    ` 
    )}
    `;
    
const TextBubble = (props) => {
    const {
        translate,
        align,
        text,
        color,
        width,
        height,
        margin,
        fontSize,
        fontFamily,
        styles,
        isVisible,
        isHideable,
    } = props;
    return (
        <StyledTextBubble
            translate={translate}
            align={align}
            color={color}
            isHideable={isHideable}
            isVisible={isVisible}
            fontSize={fontSize}
            margin={margin} 
            width={width}
            height={height}
            fontFamily={fontFamily}
            styles={styles}
            fadeAnimation={fadeAnimation}
        >
            {text}
        </StyledTextBubble>
    )
}

export default TextBubble;