import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player'
import {
    VideoWrapper,
    VideoContainer,
    VideoWrapperWrapper,
} from '../pages/Blogs/blog.styles';

const PageWrapper = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;

const ModalWrapper = styled.div`
    background: black;
    opacity: .8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
`;

const ImageWrapper = styled.div`
    position: fixed;
    padding-top: 75px;
    width: auto;
    max-width: 75%;
    margin: 0 auto;
    z-index: 150;
    display: flex;
    justify-content: center;
`;

const ImageContainer= styled.img`
    border: 2px solid white;
    height: fit-content;
    max-height: 80vh;
    @media (max-width: 1200px) {
        max-height: none;
        width: fit-content;
        max-width: 75%;
        height: auto;
    }
`;

class ImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            video: null,
        }
    }

    componentDidMount () {
        const { currentImage, currentVideo } = this.props;
        if (currentImage || currentVideo) {
            this.setModal();
        }

    }

    componentDidUpdate (prevProps) {
        const { currentImage: oldImage, currentVideo: oldVideo } = prevProps;
        const { currentImage, currentVideo } = this.props;
        if (currentImage !== oldImage) {
            this.setModal(currentImage);
        }
        if (currentVideo !== oldVideo) {
            this.setModal(currentVideo);
        }
    }

    clearModal = () => {
        const { clearModal } = this.props;
        if (clearModal) {
            clearModal();
        }
        this.setState({
            image: null,
            video: null,
        });
    }

    setModal = () => {
        const { currentImage, currentVideo } = this.props;
        this.setState({
            image: currentImage,
            video: currentVideo
        });
    }

    render() {
        const { image, video } = this.state;
        if (!image && !video) return null;
        
        if (image) {
            return (
                <PageWrapper>
                    <ModalWrapper onClick={this.clearModal} />
                    <ImageWrapper>
                        <ImageContainer
                            src={image}
                        />
                    </ImageWrapper>
                </PageWrapper>
            )
        }
        if (video) {
            return (
                <PageWrapper>
                    <ModalWrapper onClick={this.clearModal} />
                    <VideoWrapperWrapper fixed>
                        <VideoWrapper>
                            <VideoContainer>
                                <ReactPlayer
                                    width="100%"
                                    height="100%"
                                    url={video}
                                />
                            </VideoContainer>
                        </VideoWrapper>
                </VideoWrapperWrapper>
                </PageWrapper>
            )
        }
        return null;
    }
}

export default ImageModal;