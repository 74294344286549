import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaTwitter, FaYoutube, FaEnvelope, FaHeart } from 'react-icons/fa';

const FooterWrapper = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    position: relative;
    border-top: 1px solid white;

`;

const FooterColor = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgba(15%, 16%, 20%, .75);
    opacity: .9;
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: fit-content;
    text-decoration: none;
    width: fit-content;
    z-index: 10;
    position: absolute;
    left: 50%;
    bottom: 30px;
`
const NeonLetter = styled.div`
    color: ${props => props.primary || 'white'}
    ${props => `font-family: ${props.font ? props.font : 'Lunasol'};`}
    align-items: center;
    display: flex;
    font-size: 16px;
    flex-direction: row;
    height: 100%;
    letter-spacing: 1px;
    z-index: 10;
    &:hover {
        opacity: .7;
    }
`

const Footer = () => {
    return (
        <FooterWrapper>
            <FooterColor />
            <StyledLink 
                    heart
                    style={{ textDecoration: 'none' }} 
                    to="/"
                    onClick={() => { window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })}}
                >
                    <NeonLetter font="Justice">
                        <FaHeart color={'white'} />
                    </NeonLetter>
                </StyledLink>
        </FooterWrapper>
    )
};

export default Footer;