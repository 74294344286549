import React from 'react';
import styled from 'styled-components';
import TextBubble from '../../components/TextBubble';
import CurrentGames from './CurrentGames';
import Announcements from './Announcements';
import Twitter from './Twitter';
import Newsletter from './Newsletter';


const PageWrapper = styled.div`
    position: relative;
    @media (max-width: 870px) {
        padding: 0px;
    }
    padding-bottom: 50px;
`;

const topTextStyles = `
    text-align: center;
    @media (max-width: 870px) {
        padding: 10px 0px 6px 0px;
        font-size: 16px;
        margin: 5% auto 0% auto;
    }
    color: white;
    border-bottom: solid 1px #D4AC58;
    width: 70%;
    margin: 4% auto 0% auto;
    font-family: Canossa;
    @media (min-width: 2000px) {
        font-size: 30px;
    }
`;

const topSubTextStyles = `
    text-align: center;
    font-family: Canossa;
    color: white;
    margin: 6px 0px 75px 0px
    @media (max-width: 870px) {
        padding: 0px 0px 20px 0px;
        font-size: 12px;
        margin: 6px 0px 30px 0px
    }
    @media (min-width: 2000px) {
        font-size: 24px;
    }
`;


const introText = 'Gold Heart Games is an independent game development studio';
const subIntroText = 'Dreams are meant to be shared';


const HomePage = () => {

    return (
        <PageWrapper>
            <TextBubble 
                width={'85%'} 
                height={'100%'} 
                fontSize={30} 
                styles={topTextStyles} 
                text={introText}
            />
            <TextBubble 
                width={'85%'} 
                height={'100%'} 
                fontSize={22} 
                styles={topSubTextStyles} 
                text={subIntroText}
            />
            <Announcements />
            <Newsletter key={'newsletter'} />
            <CurrentGames />
            <Twitter />
        </PageWrapper>
    )
}

export default HomePage;