import React from 'react';
import styled from 'styled-components';

const BlogTextWrapper = styled.div`
    width: 100%;
    line-height: 25px;
    border-bottom: 1px solid ${props => props.color ? props.color : '#D4AC58;'}
    font-size: 1.3w;
    color: white;
    text-align: center;
    @media (max-width: 1200px) {
        font-size: 14px;
    }
`;

const BlogTextHeader = ({ color, text }) => {
    return (
        <BlogTextWrapper color={color}>
            {text}
        </BlogTextWrapper>
    )
}

export default BlogTextHeader;