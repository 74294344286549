import React from 'react';
import SectionBanner from '../../../components/SectionBanner';
import Row from '../../../components/Row';
import BlogTextHeader from '../../../components/BlogTextHeader';
import {
    PageWrapper,
    ContentWrapper,
    SectionBannerWrapper,
    rowStyles,
    ImageContainer,
    ImageWrapper,
    TextParagraph,
    TopTextWrapper,
    TextLink,
} from '../blog.styles';

const topText = {
    top: "Hello, World! My name is Nicky Boccuzzi and I am the one-man show behind Gold Heart Games. THANK YOU for taking the time to visit this website. I hope you learn something new from one of my tutorials, feel inspired by the titles in development, or give one of my completed games a shot!",
    mid: "My goal here is to record the trials and tribulations of game development and share the experience as much as possible. This should result in a clear story of progression for myself and provide insight to the mistakes made and roadblocks met along the way.",
    header: "Prelude"
};
const midText = {
    top: "A little bit about my background: I am a full time software engineer working on mobile applications for iOS and Android and I am a part-time fitness instructor on the side. The free time that I do have is spent with my loved ones or playing, reading, and fantasizing about games.",
    mid: "I learned to code in 2017. It quickly became my goal and dream to make games professionally. Suddenly my passion for playing games had evolved into a passion for making them. The game ideas I had been daydreaming about no longer had to be dreams.",
    header: "Who am I?"
};
const bottomText = {
    top: "I currently have one small web game released: 'Survival of the Typist.' I made this toward the end of my coding bootcamp and it's what made me fall in love with game development.",
    mid: `It's not much to show, but it's also a game I am really proud of. The concept in my head sounded something like this: "take 'Typing of the Dead' and turn it into a high score arcade game that can be played on any desktop." At some point I would love to remake it in Unity or Unreal.`,
    header: "What have I made?"
};
const vrText = {
    top: "When VR was first announced, I didn't think much of it. But after playing a few games at PAX and New York Comic Con, I started to believe in it a lot more. You can truly build worlds with this technology, and thats what I want to do!",
    mid: "At the time of writing this, I currently have an Oculus Rift S. I have sunk a good amount of time into Unity development and I'm beginning to work on 'NEON SKIES!'",
    header: "What am I learning?"
};


const Me = require('../../../assets/blogs/hello/nickyb.png');
const MeOculus = require('../../../assets/blogs/hello/nickybOculus.png');
const OculusCountdown = require('../../../assets/blogs/hello/oculus.gif');
const sottGif = require('../../../assets/blogs/hello/sott.gif');

const HelloWorld = () => {
    return (
        <PageWrapper>
            <ContentWrapper>
                <SectionBannerWrapper>
                    <SectionBanner align="right" text={'Hello World'} />
                </SectionBannerWrapper>
                <Row style={rowStyles}>
                    <ImageContainer>
                        <ImageWrapper src={Me} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={topText.header} />
                        <TextParagraph first>
                        {topText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {topText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer hideOnRow>
                        <ImageWrapper src={MeOculus} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={midText.header} />
                        <TextParagraph first>
                        {midText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {midText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                    <ImageContainer hideOnCol>
                        <ImageWrapper src={MeOculus} alt="Nicky B" />
                    </ImageContainer>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer>
                        <ImageWrapper src={OculusCountdown} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={vrText.header} />
                        <TextParagraph first>
                        {vrText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {vrText.mid}
                        </TextParagraph>
                    </TopTextWrapper>
                </Row>
                <Row style={rowStyles}>
                    <ImageContainer hideOnRow>
                        <ImageWrapper src={sottGif} alt="Nicky B" />
                    </ImageContainer>
                    <TopTextWrapper>
                        <BlogTextHeader text={bottomText.header} />
                        <TextParagraph first>
                        {bottomText.top}
                        </TextParagraph>
                        <TextParagraph>
                        {bottomText.mid}
                        </TextParagraph>
                        <TextLink href={'http://nicholasboccuzzi.com/Survival-of-the-Typist/'}>
                            Press Here to Play!
                        </TextLink>
                    </TopTextWrapper>
                    <ImageContainer hideOnCol>
                        <ImageWrapper src={sottGif} alt="Nicky B" />
                    </ImageContainer>
                </Row>
            </ContentWrapper>
        </PageWrapper>
    )
}

export default HelloWorld;