const neonSkiesData = {
    title: 'Neon Skies',
    description:  "Neon Skies is a futuristic VR flight action game where players enter the utopian landscape of Arcadia. As rookie pilots, players compete against one another to earn fame and glory; boosting their ranks on the league charts. The higher a pilot's rank, the more partnerships with in-game sponsors are unlocked which offer new colors, ships (aka Frames) and more! Ultimately, Neon Skies is a test of skill, as all pilots are pitted against one another. Only the best can earn the title of champion.",
    platforms: 'Oculus Rift',
    status: 'Pre-Alpha',
    link: '/#/neon-skies',
    press: 'Press the Image for More Info',
}

const survivalOfTheTypistData = {
    title: 'Survival of the Typist',
    description: "Survival of the Typist is a high-score browser game where you control and protect a 'Square' from all oncoming objects. Players alternate between text input and 'Square' control to actively reload, and trigger different abilities and weapons.",
    platforms: 'Browser',
    status: 'Complete',
    link: 'http://nicholasboccuzzi.com/Survival-of-the-Typist/',
    press: 'Press the Image to Play in Browser',
}

const crossroadsData = {
    title: 'Crossroads',
    description: "Stay Tuned...",
    platforms: '',
    status: '',
}

export const currentGameData = [neonSkiesData, survivalOfTheTypistData, crossroadsData]