import React from 'react';
import styled from 'styled-components';

const ScreenshotWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 100px auto;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
`;

const ImageWrapper = styled.div`
    width: 33.33%;
    height: auto;
    padding: 50px 20px 50px 20px;
    display: flex;
    justify-content: center;
    &:hover {
        cursor: pointer;
        border: 1px solid #D4AC58;
    }
    @media (max-width: 1200px) {
        width: 75%;
        padding: 25px 20px 25px 20px;
    }
`;

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const ImageContainer = styled.img`
    width: 90%;
    border: 2px solid white;
    align-self: center;
`;

const NeonSkiesTeaserSmall = require('../../assets/neonskies/teaserTeaser.png');

const NeonSkiesTeaser = "https://youtu.be/b7EHpUyxz-o";

const Videos = ({
    setModal
}) => {
   return (
   <ScreenshotWrapper>
       <Row>
            <ImageWrapper onClick={() => setModal(NeonSkiesTeaser, true)}>
                <ImageContainer src={NeonSkiesTeaserSmall} />
            </ImageWrapper>
        </Row>
    </ScreenshotWrapper>)
}

export default Videos;