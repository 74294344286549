import React from 'react';
import { Route } from 'react-router-dom'
import Fader from 'react-fader'
import Switch from 'react-router-transition-switch'
import NavBar from './components/Navbar';
import GoldHeartBar from './components/GoldHeartBar';
import HomePage from './pages/HomePage/index';
import './App.css';
import NeonSkiesPage from './pages/NeonSkies/index';
import About from './pages/About';

import { HOME } from './constants';
import Blogs from './pages/Blogs';

class App extends React.Component {
constructor(props) {
  super(props);

  this.state = {
    stack: [],
    page: HOME,
  };
}

updatePageState = (pageName) => {
  this.setState({page: pageName});
}

renderPages = () => {
  return (
    <Switch component={Fader}>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/neon-skies" component={NeonSkiesPage} />
      {Blogs()}
      <Route exact path="/about" component={About} />
    </Switch>
  )
}

render() {
  return (
    <div className="homepage">
      <GoldHeartBar />
      <NavBar updatePageState={this.updatePageState} />
      {this.renderPages()}
    </div>
  );
}
}

export default App;
