import React from 'react';
import styled from 'styled-components';

const StyledRow = styled.div`
    display: flex; 
    position: relative;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    max-width: 1200px;
    justify-self: center;
    align-self: center;
    margin: 40px auto 60px auto;
    ${props => props.margin ? `margin: ${props.margin};` : ''}
    @media (max-width: 1225px) {
        width: 90%
    }
    @media (max-width: 870px) {
        flex-direction: ${props => props.alwaysRow ? 'row' : 'column'};
        justify-content: center;
    }
    @media (min-width: 1920px) {
        max-width: 1500px;
    }
    ${props => props.styles}
`;

const Row = ({ children, style }) => {
    return (
    <StyledRow styles={style}>
        {children}
    </StyledRow>
    )
}

export default Row;