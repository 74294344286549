import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    FaTwitter,
    FaYoutube,
    FaEnvelope,
    FaHeart,
    FaPatreon,
} from 'react-icons/fa';
import NeonSkiesText from './NeonSkiesText';
import {
    isBrowser,
} from "react-device-detect";

const TWITTER_LINK = 'https://twitter.com/GoldHeartGames';
const EMAIL = 'mailto:goldheartgames@gmail.com';
const PATREON = 'https://www.patreon.com/NickyBoccuzzi';

const StyledNavbar = styled(Navbar)`
    margin-left: 25px;
    padding: 0px;
    width: 75%;
    z-index: 10;
    @media (max-width: 870px) {
        margin-left: 15px;
    }
`
const NavBarWrapper = styled.div`
    width: 50%;
    @media (max-width: 870px) {
        overflow: hidden;
        width: 100%;
    }
    align-items: center;
    background-color: rgba(28, 48, 122, .2);
    border: 2px solid black;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 50px;
    margin: auto;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;
const SocialMediaWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin-right: 25px;
    width: 25%;
    z-index: 10;
    @media (max-width: 870px) {
        display: none;
    }
`;
const SocialButton = styled.a`
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0px 5px;
    opacity: 1;
    padding: 2px;
    &:hover {
        cursor: pointer;
        ${props => props.isBrowser ? 'opacity: .6;' : null}
    }
    &:hover {
        opacity: .6;
    }
    `;
const NeonLetter = styled.div`
    color: ${props => props.primary || 'white'}
    ${props => `font-family: ${props.font ? props.font : 'Lunasol'};`}
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    letter-spacing: 1px;
    z-index: 10;
`
const StyledLink = styled(Link)`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: fit-content;
    padding: 1vmax;
    text-decoration: none;
    width: fit-content;
    z-index: 10;
    ${props => props.heart && (`display: none;`)}
    &:hover ${NeonLetter} {
        color: white;
        text-decoration: none;
        ${props => props.isBrowser ? 'opacity: .6;' : null}
    }
    &:active ${NeonLetter} {
        ${props => !props.isBrowser ? 'opacity: .6;' : null}
    }
    @media (max-width: 1200px) {
        ${props=> props.heart && (`display: flex;`)}
    }
    @media (max-width: 870px) {
        margin-right: 10px;
        ${props => props.sott && ('display: none;')}
    }
`

const NavButton = styled.div`
    font-size: 18px;
    height: 100%;
    @media (min-width: 2000px) {
        font-size: 24px;
    }
    @media (max-width: 870px) {
        font-size: 15px;
    }
`;

const sink = isBrowser ? 'hvr-sink' : null;

const NavBar = (props) => {
    return (
        <NavBarWrapper>
            <StyledNavbar>
                <StyledLink 
                    className={sink} 
                    heart
                    isBrowser={isBrowser}
                    style={{ textDecoration: 'none' }} 
                    to="/"
                >
                    <NeonLetter font="Justice">
                        <FaHeart color={'white'} />
                    </NeonLetter>
                </StyledLink>
                <StyledLink 
                    className={sink} 
                    star
                    isBrowser={isBrowser}
                    style={{ textDecoration: 'none' }} 
                    to="/blog"
                >
                    <NeonLetter font="Justice">
                        <NavButton>
                            DEVLOGS
                        </NavButton>
                    </NeonLetter>
                </StyledLink>
                <NeonSkiesText isBrowser={isBrowser} />
                <StyledLink
                    className={sink}
                    isBrowser={isBrowser}
                    star
                    style={{ textDecoration: 'none' }}
                    to="/about"
                    >
                    <NeonLetter font="Justice" primary="">
                        <NavButton>
                            ABOUT
                        </NavButton>
                    </NeonLetter>
                </StyledLink>
            </StyledNavbar>
            <SocialMediaWrapper>
                <SocialButton className={sink} href={TWITTER_LINK}>
                    <FaTwitter color="white" />
                </SocialButton>
                <SocialButton className={sink}>
                    <FaYoutube color="white" />
                </SocialButton>
                <SocialButton className={sink} href={EMAIL}>
                    <FaEnvelope color="white" />
                </SocialButton>
                <SocialButton className={sink} href={PATREON}>
                    <FaPatreon color="white" />
                </SocialButton>
            </SocialMediaWrapper>
        </NavBarWrapper>
    )
}

export default NavBar;